import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import store from '../../store';
import { useAlert } from 'react-alert';
import { HtmlPdf } from '../HtmlPdf';
import { useForm } from 'react-hook-form';
import { useReactToPrint } from 'react-to-print';
import { Wrapper } from './CustomerDetails.styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCustomerInfo,
  setSessionDesignId,
  setCustomerDetailsButtonsDisabled,
} from '../../store/threekit';
import { CheckCircleTwoTone } from '@ant-design/icons';
import {
  orderApi,
  emailPostMarkApi,
  countryList,
  currencyCoutryList,
  azureClientId,
} from '../../constants';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';

import {
  getTotalPriceAndQty,
  LandMarkApiPostBody,
  OrderApiPostBody,
  summaryToObj,
} from '../../utils';

export default function Customerdetails() {
  const reduxStore = store.getState().threekit;
  const { t } = useTranslation();
  const alert = useAlert();
  const dispatch = useDispatch();
  const {
    summary,
    addOnsSummary,
    language,
    country,
    customerDetailsButtonsDisabled,
  } = useSelector((s) => s.threekit);
  const summaryObj = summaryToObj(summary);
  //Get Total Price
  const countryIndex = countryList.indexOf(country);
  const { totalPrice, totalDPrice, discountedPrice } = getTotalPriceAndQty();

  //Add Customer Details in State
  const [customerDetail, setCustomerDetail] = useState({
    customer_Name: '',
    customer_Email: '',
    customer_Phone: '',
    store_Advisor_Id: '',
    store_Designer_Id: '',
  });

  /*Customer Form Validation*/
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [timeoutDisabled, setTimeoutDisabled] = useState(false);
  const networkDisabled = customerDetailsButtonsDisabled;
  function withDisabledButtons(func) {
    return async (e) => {
      dispatch(setCustomerDetailsButtonsDisabled(true));
      await func(e);
      dispatch(setCustomerDetailsButtonsDisabled(false));
    };
  }

  /*Send Email to Customer*/
  const sendEmail = (e) => {
    dispatch(setCustomerInfo(customerDetail));
    const position = language === 'en' ? 'ltr' : 'rtl';

    const PM_Headers = new Headers();
    const urlEncoded = new URLSearchParams();
    PM_Headers.append('Content-Type', 'application/json');
    //Get Current Model Configuration
    urlEncoded.append('name', customerDetail.customer_Name);
    urlEncoded.append('email', customerDetail.customer_Email);
    urlEncoded.append('designer_id', reduxStore.designId);

    urlEncoded.append('m_price', totalPrice);
    urlEncoded.append(
      'd_price',
      totalDPrice > 0 ? totalPrice - discountedPrice : totalPrice
    );

    urlEncoded.append('snapshot', reduxStore.playerSnapshot);
    urlEncoded.append('list', JSON.stringify(summaryObj));
    urlEncoded.append('addon', JSON.stringify(addOnsSummary));

    urlEncoded.append('current_lang', language);
    urlEncoded.append('country', t(`country.${country}`));
    urlEncoded.append('country_index', countryIndex);
    //Template Text Info
    urlEncoded.append('template_logo', t('postmark.template_logo'));
    // urlEncoded.append('template_lang', t('postmark.template_lang'));
    urlEncoded.append('template_lang', position);
    urlEncoded.append('subject_type', t('postmark.subject_type'));

    urlEncoded.append('modular_planner', t('postmark.modular_planner'));
    urlEncoded.append('dear', t('postmark.dear'));
    urlEncoded.append('thank_you_message', t('postmark.thank_you_message'));

    urlEncoded.append('visit_message', t('postmark.visit_message'));
    urlEncoded.append('your_designer_id', t('postmark.your_designer_id'));
    urlEncoded.append('component_details', t('postmark.component_details'));

    urlEncoded.append('item_list', t('postmark.item_list'));
    urlEncoded.append('vat', t('postmark.vat'));
    urlEncoded.append('footer', t('postmark.footer'));

    urlEncoded.append('total', t('postmark.total'));
    urlEncoded.append('copy_right', t('postmark.copy_right'));

    urlEncoded.append('bar_code', t('postmark.bar_code'));
    urlEncoded.append('template_position', t('postmark.template_position'));

    var requestOptions = {
      method: 'POST',
      headers: PM_Headers,
      body: urlEncoded,
      mode: 'no-cors',
      redirect: 'follow',
    };

    return fetch(`${emailPostMarkApi}`, requestOptions)
      .then(function (response) {
        console.log('App', response);
        alert.success(`${t('server_alert.sent')}`);
        setTimeoutDisabled(true);
        setTimeout(() => {
          setTimeoutDisabled(false);
        }, 30000);
        return storeOrderLandMark('email');
      })
      .catch((error) => console.log('error', error));
  };

  const { instance, accounts } = useMsal();
  const request = {
    ...loginRequest,
    account: accounts[0],
  };

  const getRefreshToken = () => {
    //Request for Resfresh Token
    instance.acquireTokenPopup(request).then((response) => {
      console.log('Refresh Token', response);
      localStorage.setItem('app-access-token', response.accessToken);
    });
    setTimeout(() => {
      storeOrderLandMark('print');
    }, 2000);
  };

  /*Store Order On Threekit */
  const storeOrderThreekit = (refId, action) => {
    const TK_Headers = new Headers();
    TK_Headers.append('Content-Type', 'application/json');
    var raw = OrderApiPostBody(refId);
    var requestOptionsTk = {
      method: 'POST',
      headers: TK_Headers,
      body: raw,
      redirect: 'follow',
    };
    fetch(`${orderApi}`, requestOptionsTk)
      .then(function (response) {
        if (response.status === 200 && response.ok === true) {
          console.log(response);
          alert.success(`${t('server_alert.tk')}`);
          /* if (action === 'print') {
            setTimeout(() => {
              handlePrint();
            }, 5000);
          } */
        } else {
          alert.error(`${t('server_alert.something_wrong')}`);
        }
      })
      .catch((error) => console.log('error', error));
  };

  /*Store Order On LandMark Server */
  const storeOrderLandMark = (action) => {
    let accessToken = localStorage.getItem('app-access-token');
    let localInfo = JSON.parse(localStorage.getItem('store-code'));
    const LM_Headers = new Headers();
    LM_Headers.append('x-ibm-client-id', azureClientId);
    LM_Headers.append('Authorization', `Bearer ${accessToken}`);
    LM_Headers.append('Content-Type', 'application/json');
    var raw = LandMarkApiPostBody(action);
    var requestOptionsLm = {
      method:
        reduxStore.sessionDesignId === reduxStore.designId ? 'PUT' : 'POST',
      headers: LM_Headers,
      body: raw,
      redirect: 'follow',
    };
    const rowLmAPI = `https://api.landmarkgroup.com/v2/concepts/4/territories/${localInfo['Territory Code']}/locations/${localInfo['Store Code']}/modular-designs/${reduxStore.designId}`;
    return fetch(`${rowLmAPI}`, requestOptionsLm)
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        if (response.httpCode === 200) {
          alert.success(`${t('server_alert.lm')}`);
          const referenceID = response.lmgReferenceId;
          dispatch(setSessionDesignId(reduxStore.designId));
          setTimeout(() => {
            storeOrderThreekit(referenceID, action);
          }, 5000);
        } else if (response.httpCode === 400) {
          alert.error(`${t('server_alert.already_exist')}`);
        } else if (response.httpCode === 401) {
          alert.error(`${t('server_alert.wrong_input')}`);
        } else if (response.httpCode === 500) {
          alert.error(`${t('server_alert.internal_server')}`);
        } else {
          getRefreshToken();
        }
      })
      .catch((error) => console.log('LM Error', error));
  };

  /*Generate Print with PDF */
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      dispatch(setCustomerDetailsButtonsDisabled(true));
    },
    onAfterPrint: () => {
      storeOrderLandMark('print').then(() => {
        dispatch(setCustomerDetailsButtonsDisabled(false));
      });
    },
  });

  const generatePDF = () => {
    dispatch(setCustomerInfo(customerDetail));
    //storeOrderLandMark('print');
    handlePrint();
  };

  /*Form Reset on Change Language */
  useEffect(() => {
    reset();
  }, [language]);

  return (
    <>
      <div className="d-none">
        <HtmlPdf
          ref={componentRef}
          customerName={customerDetail.customer_Name}
          designId={customerDetail.store_Designer_Id}
        />
      </div>
      <Wrapper>
        <h5 className="all-title">{t('customer.customerDetails')}</h5>
        <div className="row m-0">
          <form className="p-0 from-inputs">
            <div className="form-group">
              <label className="cust-det f-700">
                {t('customer.name')}
                {'*'}
              </label>
              <input
                type="text"
                className="form-control-input"
                placeholder={t('placeholder.name')}
                id="customer_name"
                name="customer_name"
                {...register('customerName', {
                  onChange: (e) => {
                    setCustomerDetail({
                      ...customerDetail,
                      customer_Name: e.target.value,
                    });
                  },
                  required: `${t('formValidation.name')}`,
                  pattern: {
                    value: /^[a-zA-Zء-ي_ ]*$/,
                    message: `${t('formValidationMessage.name')}`,
                  },
                })}
              />
              {errors.customerName && (
                <small className="validation-errors font-weight-bolder">
                  {errors.customerName.message}
                </small>
              )}
            </div>

            <div className="form-group">
              <label className="cust-det f-700">{t('customer.email')}</label>
              {'*'}
              <input
                type="text"
                className="form-control-input"
                placeholder={t('placeholder.email')}
                id="customer_email"
                name="customer_email"
                {...register('customerEmail', {
                  onChange: (e) => {
                    setCustomerDetail({
                      ...customerDetail,
                      customer_Email: e.target.value,
                    });
                  },
                  required: `${t('formValidation.email')}`,
                  pattern: {
                    value:
                      /[\u0621-\u064A\u0660-\u0669a-zA-Z0-9\+\.\_\%\-\+]{1,256}\@[\u0621-\u064A\u0660-\u0669a-zA-Z0-9][\u0621-\u064A\u0660-\u0669a-zA-Z0-9\-]{0,64}(\.[\u0621-\u064A\u0660-\u0669a-zA-Z0-9][\u0621-\u064A\u0660-\u0669a-zA-Z0-9\-]{0,25})+/,
                    message: `${t('formValidationMessage.email')}`,
                  },
                })}
              />
              {errors.customerEmail && (
                <small className="validation-errors font-weight-bolder">
                  {errors.customerEmail.message}
                </small>
              )}
            </div>

            <div className="form-group">
              <label className="cust-det f-700">{t('customer.phone')}</label>
              <div className="contact_input">
                <div className="counter_code ltr">
                  +{reduxStore.countryCode}
                </div>
                <input
                  type="text"
                  className="form-control-input"
                  placeholder={t('placeholder.phone')}
                  id="customer_phone"
                  name="customer_phone"
                  {...register('customer_phone', {
                    onChange: (e) => {
                      setCustomerDetail({
                        ...customerDetail,
                        customer_Phone: e.target.value,
                      });
                    },
                    required: `${t('formValidation.phone')}`,
                    minLength: {
                      value: 8,
                      message: `${t('formValidationMessage.phone')}`,
                    },
                    maxLength: {
                      value: 10,
                      message: `${t('formValidationMessage.phone')}`,
                    },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: `${t('formValidationMessage.phone')}`,
                    },
                  })}
                />
                <div className="counter_code rtl">
                  {reduxStore.countryCode}+
                </div>
              </div>

              {errors.customer_phone && (
                <small className="validation-errors font-weight-bolder">
                  {errors.customer_phone.message}
                </small>
              )}
            </div>

            <h3 className="d-block all-title mt-5">
              {t('customer.storeAssociateDetails')}
            </h3>
            <div className="form-group">
              <label className="cust-det f-700">
                {t('customer.salesAdviserId')}
                {'*'}
              </label>
              <input
                type="text"
                className="form-control-input"
                placeholder={t('placeholder.salesAdviserId')}
                id="store_adviser_id"
                name="store_adviser_id"
                {...register('customer_advisor_id', {
                  onChange: (e) => {
                    setCustomerDetail({
                      ...customerDetail,
                      store_Advisor_Id: e.target.value,
                    });
                  },
                  required: `${t('formValidation.salesAdviserId')}`,
                  minLength: {
                    value: 4,
                    message: `${t('formValidationMessage.salesAdviserId.min')}`,
                  },
                  maxLength: {
                    value: 10,
                    message: `${t('formValidationMessage.salesAdviserId.max')}`,
                  },
                  pattern: {
                    value: /^[\d]*$/,
                    message: `${t(
                      'formValidationMessage.salesAdviserId.invalid'
                    )}`,
                  },
                })}
              />
              {errors.customer_advisor_id && (
                <small className="validation-errors font-weight-bolder">
                  {errors.customer_advisor_id.message}
                </small>
              )}
            </div>
            <div className="form-group">
              <label className="cust-det f-700">
                {t('customer.interiorDesignerId')}
              </label>
              <input
                type="text"
                className="form-control-input"
                placeholder={t('placeholder.interiorDesignerId')}
                id="store_designer_id"
                name="store_designer_id"
                {...register('customer_designer_id', {
                  onChange: (e) => {
                    setCustomerDetail({
                      ...customerDetail,
                      store_Designer_Id: e.target.value,
                    });
                  },
                  minLength: {
                    value: 4,
                    message: `${t(
                      'formValidationMessage.interiorDesignerId.min'
                    )}`,
                  },
                  maxLength: {
                    value: 10,
                    message: `${t(
                      'formValidationMessage.interiorDesignerId.max'
                    )}`,
                  },
                  pattern: {
                    value: /^[\d]*$/,
                    message: `${t(
                      'formValidationMessage.interiorDesignerId.invalid'
                    )}`,
                  },
                })}
              />
              {errors.customer_designer_id && (
                <small className="validation-errors font-weight-bolder">
                  {errors.customer_designer_id.message}
                </small>
              )}
            </div>
          </form>
          <div className="d-flex flex-column-fluid flex-lg-row flex-md-row justify-content-around mt-4">
            <button
              className="form-button"
              style={{ background: networkDisabled ? '#cbc7c7' : '' }}
              disabled={networkDisabled}
              onClick={handleSubmit(generatePDF)}
            >
              {t('customer.print')}
            </button>
            <button
              className="form-button send-button"
              style={{
                background: timeoutDisabled || networkDisabled ? '#cbc7c7' : '',
              }}
              disabled={timeoutDisabled || networkDisabled}
              onClick={handleSubmit(withDisabledButtons(sendEmail))}
            >
              {timeoutDisabled ? <CheckCircleTwoTone /> : ''}{' '}
              {t('customer.sendEmail')}
            </button>
          </div>
        </div>
      </Wrapper>
    </>
  );
}
