import {
  countryList,
  currencyCoutryList,
  defaultProductCategory,
  REACT_APP_THREEKIT_ORG,
} from '../constants';
import store from '../store';
import { setUndoConfigArray } from '../store/threekit';

export const camelToSpaces = (text) => {
  if (!text) return;
  var result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const LandMarkApiPostBody = (action) => {
  //Get Current Date & Time
  var curDate = new Date();
  let date =
    curDate.getFullYear() +
    '-' +
    (curDate.getMonth() + 1) +
    '-' +
    curDate.getDate();
  let time =
    curDate.getHours() +
    ':' +
    curDate.getMinutes() +
    ':' +
    curDate.getSeconds();
  const reduxStore = store.getState().threekit;
  const { totalPrice, totalDPrice, discountedPrice, totalQty, items } =
    getTotalPriceAndQty();
  const storeCode = JSON.parse(localStorage.getItem('store-code')) || {};

  const {
    country,
    customerInfo: {
      customer_Email,
      customer_Name,
      customer_Phone,
      store_Advisor_Id,
      store_Designer_Id,
    },
  } = reduxStore;

  return JSON.stringify({
    design: {
      design_id: reduxStore.designId,
      threekit_config_id: reduxStore.undoConfigArray.at(-1),
      date: date + ' ' + time,
      category: reduxStore.category,
      range: reduxStore.collection,
      design_value: totalDPrice > 0 ? totalPrice - discountedPrice : totalPrice,
      design_currency: currencyCoutryList[country],
      total_qty: totalQty,
      territory_name: storeCode['Territory'],
      territory_code: storeCode['Territory Code'],
      store_name: storeCode['Store Name'],
      store_code: storeCode['Store Code'],
      action: action,
      associate_hrmsId: store_Advisor_Id,
      designer_hrmsId: store_Designer_Id,
      customer_name: customer_Name,
      customer_email: customer_Email,
      customer_contact_no: `+${reduxStore.countryCode + customer_Phone}`,
      items: items,
    },
  });
};

export const OrderApiPostBody = (refId) => {
  const reduxStore = store.getState().threekit;
  const { totalDPrice, totalPrice, discountedPrice } = getTotalPriceAndQty();
  const storeCode = JSON.parse(localStorage.getItem('store-code'));
  const {
    country,
    customerInfo: { customer_Name, store_Designer_Id },
  } = reduxStore;

  return JSON.stringify({
    name: reduxStore.category,
    customerId: REACT_APP_THREEKIT_ORG,
    originOrgId: REACT_APP_THREEKIT_ORG,
    platform: {
      id: refId,
      platform: 'LandMark',
      storeName: storeCode['Store Name'],
      hrmsid: store_Designer_Id,
    },
    metadata: {
      name: customer_Name,
      storeid: storeCode['Store Code'],
      hrmsid: store_Designer_Id,
      range: reduxStore.collection,
    },
    items: [
      {
        id: store_Designer_Id,
        count: 1,
      },
    ],
    cart: [
      {
        configurationId: reduxStore.designId,
        count: 1,
        metadata: {
          configurl: 'http://www.google.com',
          currency: currencyCoutryList[country],
          cartvalue:
            totalDPrice > 0 ? totalPrice - discountedPrice : totalPrice,
        },
      },
    ],
    status: 'New',
    orgId: REACT_APP_THREEKIT_ORG,
  });
};

export const getTotalPriceAndQty = () => {
  const reduxStore = store.getState().threekit;
  const countryIndex = countryList.indexOf(reduxStore.country);
  const summaryArray = Object.values(reduxStore.summary);
  const addOnsSummaryArray = Object.values(reduxStore.addOnsSummary);

  const combinePriceArr = [...summaryArray, ...addOnsSummaryArray];
  let totalPrice = 0.0;
  let totalDPrice = 0.0;
  let discountedPrice = 0;
  let items = [];
  let totalQty = 0;
  combinePriceArr.forEach(({ sku, short, en, price, dPrice, qty }) => {
    let obj = {};
    const tPrice = price[countryIndex] * qty;
    const dPriceOfProduct = dPrice.length
      ? (dPrice[countryIndex] || 0) * qty
      : 0;

    totalPrice = totalPrice + tPrice;
    totalDPrice = totalDPrice + dPriceOfProduct;
    if (dPriceOfProduct !== 0) {
      discountedPrice = discountedPrice + tPrice - dPriceOfProduct;
    }
    totalQty = totalQty + qty;
    obj['item_code'] = sku;
    obj['addon'] = short ? 'N' : 'Y';
    obj['item_desc'] = en;
    obj['variant'] = short;
    obj['qty'] = qty;
    items.push(obj);
  });
  return { totalPrice, totalDPrice, discountedPrice, totalQty, items };
};

export const transformProducts = (productRows) => {
  let products = { Sofa: {}, Bed: {} };
  productRows.forEach(
    ({
      value: {
        Collection_Name,
        Type,
        Color_Variant,
        Size_Variant,
        Sku,
        English_Names,
        Arabic_Names,
        Short_Description,
        AE,
        RY,
        JE,
        DM,
        OM,
        QA,
        KW,
        BH,
        EG,
        AE_discounted,
        RY_discounted,
        DM_discounted,
        JE_discounted,
        OM_discounted,
        QA_discounted,
        KW_discounted,
        BH_discounted,
        EG_discounted,
        AE_available,
        RY_available,
        JE_available,
        DM_available,
        OM_available,
        QA_available,
        KW_available,
        BH_available,
        EG_available,
      },
    }) => {
      // assign model category. !!!important - we have Size_Variant and Type in bed products only
      const isSofa = !Size_Variant;
      const sizeVariant = isSofa ? defaultProductCategory : Size_Variant; // reassign sofa size variants
      const productType = isSofa ? defaultProductCategory : Type; // reassign sofa types
      // assign keys: category => collection => type => size => shortName => color
      const currentCategory = isSofa ? products.Sofa : products.Bed;

      if (!(Collection_Name in currentCategory))
        currentCategory[Collection_Name] = {};
      if (!(productType in currentCategory[Collection_Name]))
        currentCategory[Collection_Name][productType] = {};
      if (!(sizeVariant in currentCategory[Collection_Name][productType]))
        currentCategory[Collection_Name][productType][sizeVariant] = {};
      const short = Short_Description.includes('Drawer Base')
        ? 'Drawer Base'
        : Short_Description;
      if (
        !(short in currentCategory[Collection_Name][productType][sizeVariant])
      )
        currentCategory[Collection_Name][productType][sizeVariant][short] = {};

      const productObj =
        currentCategory[Collection_Name][productType][sizeVariant][short];

      // assign color key
      if (!(Color_Variant in productObj)) productObj[Color_Variant] = {};

      // assign names
      productObj.en = English_Names;
      productObj.ar = Arabic_Names;
      productObj.short = short;
      // assign prices
      let price = [];
      let discountedPrice = [];
      countryList.forEach((country, i) => {
        // use forEach to reduce mapping zeros
        const currentPrice = eval(country);
        const discounted = eval(`${country}_discounted`);
        const isAvailable = JSON.parse(
          eval(`${country}_available`).toLowerCase()
        ); // "TRUE"/"FALSE" string to boolean

        if (isAvailable) {
          price[i] = currentPrice;
          if (currentPrice !== discounted) discountedPrice[i] = discounted;
        }
      });
      productObj[Color_Variant].sku = Sku;
      productObj[Color_Variant].price = price;
      productObj[Color_Variant].dPrice = discountedPrice;
    }
  );
  return products;
};

export const transformAddons = (addonRows) => {
  let products = { Sofa: {}, Bed: {} };
  addonRows.forEach(
    ({
      value: {
        Collection_Name,
        Size,
        Image_No,
        Rank,
        English_Names,
        Arabic_Names,
        Sku,
        AE,
        JE,
        RY,
        DM,
        BH,
        KW,
        QA,
        OM,
        EG,
        AE_discounted,
        RY_discounted,
        JE_discounted,
        DM_discounted,
        BH_discounted,
        KW_discounted,
        QA_discounted,
        OM_discounted,
        EG_discounted,
      },
    }) => {
      // assign model category. !!!important - we have Size and Type in bed products only
      const isSofa = !Size;
      const sizeVariant = isSofa ? defaultProductCategory : Size; // reassign sofa size variants
      const productType = isSofa ? defaultProductCategory : Size; // reassign sofa types
      // assign keys: category => collection => type => size => shortName => color
      const currentCategory = isSofa ? products.Sofa : products.Bed;

      if (!(Collection_Name in currentCategory))
        currentCategory[Collection_Name] = {};

      if (!(productType in currentCategory[Collection_Name]))
        currentCategory[Collection_Name][productType] = {};

      if (!(sizeVariant in currentCategory[Collection_Name][productType]))
        currentCategory[Collection_Name][productType][sizeVariant] = {};

      if (
        !(
          English_Names in
          currentCategory[Collection_Name][productType][sizeVariant]
        )
      )
        currentCategory[Collection_Name][productType][sizeVariant][
          English_Names
        ] = {};

      const productObj =
        currentCategory[Collection_Name][productType][sizeVariant][
          English_Names
        ];

      if (!(English_Names in productObj)) productObj[English_Names] = {};

      // assign names
      productObj.en = English_Names;
      productObj.ar = Arabic_Names;
      productObj.img = Image_No;
      productObj.rank = Rank;

      // assign prices
      let price = [];
      let discountedPrice = [];
      countryList.forEach((country, i) => {
        // use forEach to reduce mapping zeros
        const currentPrice = eval(country);
        const discounted = eval(`${country}_discounted`);

        if (currentPrice) {
          price[i] = currentPrice; // if country price is empty...
        } else {
          price[i] = 0;
        }
        if (discounted) {
          discountedPrice[i] = discounted;
        } else {
          discountedPrice[i] = 0;
        }
      });
      productObj.sku = Sku;
      productObj.price = price;
      productObj.dPrice = discountedPrice;
    }
  );
  return products;
};

export async function setModelAttribute(id, attributeKey, attributeValue) {
  /*Function to set configuration with selected Color for selected asset*/

  const instanceId = await window.threekit.api.player.getAssetInstance({
    id,
    plug: 'Null',
    property: 'asset',
  });

  const selectedModelSceneId = await window.threekit.api.scene.get({
    id: instanceId,
    evalNode: true,
  });

  // get a list of selected model colors
  const modelAttributes =
    await selectedModelSceneId.configurator.getAttributes();

  const attributeValues =
    modelAttributes.find((item) => item.name === attributeKey)?.values || [];

  let attributeConfig = {};

  if (attributeKey === 'Ranges' || attributeKey === 'Size') {
    const value = attributeValues.find((item) => item === attributeValue);
    attributeConfig = { [attributeKey]: value };
  } else {
    // need to check if working
    const attributeValueId = attributeValues.find(
      (item) => item.metadata.product === attributeValue
    )?.assetId;

    if (!attributeValueId) return;
    attributeConfig = {
      [attributeKey]: {
        assetId: attributeValueId,
      },
    };
  }

  // set model color
  await selectedModelSceneId.configurator.setConfiguration(attributeConfig);
}
// TODO remove this function and use from configurator/src/v1/helpers.js:getConfigOnModel()
export async function getConfigOnModel(modelNullId) {
  const instanceId = await window.threekit.api.player.getAssetInstance({
    id: modelNullId,
    plug: 'Null',
    property: 'asset',
  });

  return window.threekit.api.scene
    .get({ id: instanceId, evalNode: true })
    .configurator.getConfiguration();
}

export async function setConfigOnModel(modelNullId, config) {
  try {
    // Optimization for savedInstanceId
    // const reduxStore = store.getState().threekit;
    // const savedInstanceId = reduxStore.modelInstanceIds[modelNullId];

    const instanceId =
      // savedInstanceId ||
      await window.threekit.api.player.getAssetInstance({
        id: modelNullId,
        plug: 'Null',
        property: 'asset',
      });

    await window.threekit.api.scene
      .get({ id: instanceId, evalNode: true })
      .configurator.setConfiguration(config);
  } catch (err) {
    console.log(`err [setConfigOnModel] = `, err);
  }
}

// TODO: remove function
export const setModelAttributeConfig = (key, value) => {
  if (key === 'Ranges' || key === 'Size') {
    return {
      [key]: value,
    };
  }
  return {
    [key]: value,
  };
};

export const summaryToObj = (summary) => {
  let summaryObj = {};
  summary.forEach((item) => {
    if (summaryObj[item.sku]) {
      summaryObj[item.sku] = {
        ...summaryObj[item.sku],
        qty: summaryObj[item.sku].qty + 1,
      };
    } else {
      summaryObj = { ...summaryObj, [item.sku]: item };
    }
  });
  return summaryObj;
};

export const saveUndoConfiguration = async () => {
  const { designId, collection, type, undoConfigArray, color } =
    store.getState().threekit;

  const configId = await window.threekit.configurator.saveConfiguration({
    designId,
    collection,
    type,
    color,
  });

  const newPlayerConfigs = [...undoConfigArray, configId];
  store.dispatch(setUndoConfigArray(newPlayerConfigs));
};

store.subscribe(() => LandMarkApiPostBody());
